import { IComponentGFPPDefinition } from '@wix/editor-elements-types';
import { createGFPPEntry } from '@wix/editor-elements-integrations';

export const UnsupportedComponentGFPP: IComponentGFPPDefinition = {
  getGfppData() {
    return {
      mainActions: [
        {
          label: 'gfpp_mainaction_deprecated_component',
          actionType: null,
        },
      ] as any,
      enabledActions: ['help'],
      helpId: '',
    };
  },
};

export default createGFPPEntry(UnsupportedComponentGFPP);
