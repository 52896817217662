
    import { createGFPPEntry } from '@wix/editor-elements-integrations';
    import { assignTranslatedLabels } from '@wix/lean-components-infra/runtime/translations';

    export default createGFPPEntry({
      getGfppData() {
        return {
          mainActions: assignTranslatedLabels([{"actionType":"settings","shouldTranslate":false}]),
          enabledActions: [ 'help'],
          mobileEnabledActions: ['help'],
          helpId: 'TODO',
          mobileHelpId: 'TODO',
        };
      },
    });
  