import { IComponentGFPPDefinition } from '@wix/editor-elements-types';
import { createGFPPEntry } from '@wix/editor-elements-integrations';
import { HelpIds, TranslationKeys } from '../constants';

export const CollapsibleTextGFPP: IComponentGFPPDefinition = {
  getGfppData() {
    return {
      mainActions: [
        {
          label: TranslationKeys.settings.edit_text_label,
          actionType: 'manage',
        },
      ],
      enabledActions: [
        'settings',
        'layout',
        'design',
        'animation',
        'stretch',
        'help',
        'connect',
        'manage',
      ],
      mobileEnabledActions: [
        /**
         * Following scaling gfpp buttons are temporarily disabled due to an API issue
         * More details can be found here:
         * https://wix.slack.com/archives/C01N8H88KAP/p1633437555010100
         */
        // 'scale_up',
        // 'scale_down',
        'layout',
        'animation',
        'hide',
        'help',
      ],
      helpId: HelpIds.gfpp,
      mobileHelpId: HelpIds.mobileGfpp,
    };
  },
};

export default createGFPPEntry(CollapsibleTextGFPP);
