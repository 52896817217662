import { IComponentGFPPDefinition, CompRef } from '@wix/editor-elements-types';
import { createGFPPEntry } from '@wix/editor-elements-integrations';
import { Image } from '@wix/thunderbolt-components-native';

export const ProGalleryGFPP: IComponentGFPPDefinition = {
  getGfppData() {
    return {
      mainActions: [
        {
          label(editorAPI: any, compRef: CompRef) {
            return editorAPI.components.data.get(compRef)?.items?.length
              ? 'gfpp_mainaction_gallery'
              : 'gfpp_mainaction_gallery_empty';
          },
          // isApplied: gfppDataUtils.getDataDefinedFn('items'),
          onClick(editorAPI: any, compRef: Array<CompRef>) {
            editorAPI.panelHelpers.openOrganizeImagesPanel(function (
              newImageDataList: Array<Image>,
            ) {
              const data = editorAPI.components.data.get(compRef);
              data.items = newImageDataList;
              editorAPI.components.data.update(compRef, data);
            });
          },
          // TODO - label is marked as string but can also be a function
        } as any,
      ],
      enabledActions: [
        'layout',
        'design',
        'settings',
        'animation',
        'stretch',
        'help',
        // 'connect',
      ],
      mobileEnabledActions: [
        'layout',
        'design',
        'settings',
        'animation',
        'stretch',
        'hide',
        'help',
      ],
      helpId: 'replace with real id',
      mobileHelpId: 'replace with real id',
    };
  },
};

export default createGFPPEntry(ProGalleryGFPP);
