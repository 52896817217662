import wixui_BreadcrumbsGfpp from '../components/Breadcrumbs/editor/Breadcrumbs.gfpp';
import wixui_BreadcrumbsMetadata from '../components/Breadcrumbs/editor/Breadcrumbs.editorMetadata';
import wixui_CollapsibleTextGfpp from '../components/CollapsibleText/editor/CollapsibleText.gfpp';
import wixui_CollapsibleTextMetadata from '../components/CollapsibleText/editor/CollapsibleText.editorMetadata';
import wixui_ProGalleryGfpp from '../components/ProGallery/editor/ProGallery.gfpp';
import wixui_ProGalleryMetadata from '../components/ProGallery/editor/ProGallery.editorMetadata';
import wixui_SliderGfpp from '../components/Slider/editor/Slider.gfpp';
import wixui_SliderMetadata from '../components/Slider/editor/Slider.editorMetadata';
import wixui_StylableHorizontalMenuGfpp from '../components/StylableHorizontalMenu/editor/StylableHorizontalMenu.gfpp';
import wixui_MultiStateBoxGfpp from '../components/MultiStateBox/editor/MultiStateBox.gfpp';
import wixui_MultiStateBoxMetadata from '../components/MultiStateBox/editor/MultiStateBox.editorMetadata';
import wixui_MegaMenuContainerItemGfpp from '../components/MegaMenuContainerItem/editor/MegaMenuContainerItem.gfpp';
import wixui_MegaMenuContainerItemMetadata from '../components/MegaMenuContainerItem/editor/MegaMenuContainerItem.editorMetadata';
import wixui_TestCompGfpp from '../components/TestComp/editor/TestComp.gfpp';
import wixui_TestCompMetadata from '../components/TestComp/editor/TestComp.editorMetadata';
import wixui_UnsupportedComponentGfpp from '../components/UnsupportedComponent/editor/UnsupportedComponent.gfpp';
import wixui_UnsupportedComponentMetadata from '../components/UnsupportedComponent/editor/UnsupportedComponent.editorMetadata';
import wixui_RangeSliderGfpp from '../.components/RangeSlider/editor/RangeSlider.gfpp';
import wixui_RangeSliderMetadata from '../.components/RangeSlider/editor/RangeSlider.editorMetadata';
import wixui_MockLibCompGfpp from '../.components/MockLibComp/editor/MockLibComp.gfpp';
import wixui_MockLibCompMetadata from '../.components/MockLibComp/editor/MockLibComp.editorMetadata';
import wixui_StepperGfpp from '../.components/Stepper/editor/Stepper.gfpp';
import wixui_StepperMetadata from '../.components/Stepper/editor/Stepper.editorMetadata';


const wixui_Breadcrumbs = {
  gfpp: wixui_BreadcrumbsGfpp,
  metadata: wixui_BreadcrumbsMetadata
};

const wixui_CollapsibleText = {
  gfpp: wixui_CollapsibleTextGfpp,
  metadata: wixui_CollapsibleTextMetadata
};

const wixui_ProGallery = {
  gfpp: wixui_ProGalleryGfpp,
  metadata: wixui_ProGalleryMetadata
};

const wixui_Slider = {
  gfpp: wixui_SliderGfpp,
  metadata: wixui_SliderMetadata
};

const wixui_StylableHorizontalMenu = {
  gfpp: wixui_StylableHorizontalMenuGfpp
};

const wixui_MultiStateBox = {
  gfpp: wixui_MultiStateBoxGfpp,
  metadata: wixui_MultiStateBoxMetadata
};

const wixui_MegaMenuContainerItem = {
  gfpp: wixui_MegaMenuContainerItemGfpp,
  metadata: wixui_MegaMenuContainerItemMetadata
};

const wixui_TestComp = {
  gfpp: wixui_TestCompGfpp,
  metadata: wixui_TestCompMetadata
};

const wixui_UnsupportedComponent = {
  gfpp: wixui_UnsupportedComponentGfpp,
  metadata: wixui_UnsupportedComponentMetadata
};

const wixui_RangeSlider = {
  gfpp: wixui_RangeSliderGfpp,
  metadata: wixui_RangeSliderMetadata
};

const wixui_MockLibComp = {
  gfpp: wixui_MockLibCompGfpp,
  metadata: wixui_MockLibCompMetadata
};

const wixui_Stepper = {
  gfpp: wixui_StepperGfpp,
  metadata: wixui_StepperMetadata
};


export const components = {
  ['wixui.Breadcrumbs']: wixui_Breadcrumbs,
  ['wixui.CollapsibleText']: wixui_CollapsibleText,
  ['wixui.ProGallery']: wixui_ProGallery,
  ['wixui.Slider']: wixui_Slider,
  ['wixui.StylableHorizontalMenu']: wixui_StylableHorizontalMenu,
  ['wixui.MultiStateBox']: wixui_MultiStateBox,
  ['wixui.MegaMenuContainerItem']: wixui_MegaMenuContainerItem,
  ['wixui.TestComp']: wixui_TestComp,
  ['wixui.UnsupportedComponent']: wixui_UnsupportedComponent,
  ['wixui.RangeSlider']: wixui_RangeSlider,
  ['wixui.MockLibComp']: wixui_MockLibComp,
  ['wixui.Stepper']: wixui_Stepper
};


// temporary export
export const version = "1.0.0"
