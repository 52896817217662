import { IComponentGFPPDefinition } from '@wix/editor-elements-types';
import { createGFPPEntry } from '@wix/editor-elements-integrations';
import { automationId, TranslationKeys } from '../constants';
import { openMenuManager, openPagesPanel } from './utils';

export const StylableHorizontalMenuGFPP: IComponentGFPPDefinition = {
  getGfppData() {
    return {
      mainActions: [
        {
          label: TranslationKeys.gfpp.manageMenu,
          onClick(editorAPI, compRef) {
            if (editorAPI.menus.__UNSAFE__isCustomMenusEnabled()) {
              openMenuManager(editorAPI);
            } else {
              const { id: menuId } = editorAPI.components.data.get(compRef) || {
                id: 'CUSTOM_MAIN_MENU',
              };
              openPagesPanel(editorAPI, menuId);
            }
          },
          automationId,
        },
        {
          label: TranslationKeys.gfpp.navigate,
          onClick(editorAPI) {
            editorAPI.openQuickNavigation(
              editorAPI,
              'GFPP_StylableHorizontalMenu',
            );
          },
        },
      ],
      enabledActions: [
        'layout',
        'stylable',
        'design',
        'animation',
        'stretch',
        'help',
      ],
      mobileEnabledActions: ['hide', 'help', 'animation', 'design'],
      helpId: 'ac4ace90-6edf-40c3-91d2-707d554e9e07',
      mobileHelpId: 'ac4ace90-6edf-40c3-91d2-707d554e9e07',
    };
  },
};

export default createGFPPEntry(StylableHorizontalMenuGFPP);
