import { IEditorMetadata } from '@wix/editor-elements-types';
import {
  createEditorMetadataEntry,
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-elements-integrations';
import { CollapsibleTextDSProps } from '../CollapsibleText.types';

const geHorizontallyResizable = editorDataFunction(
  componentInstanceData('componentProperties'),
  (props: CollapsibleTextDSProps) =>
    !(!props.ellipsis && props.wrappingStyle === 'single-line'),
);

const CollapsibleTextEditorMetadata: IEditorMetadata = {
  allowConnectToDB: true,
  verticallyResizable: false,
  horizontallyResizable: geHorizontallyResizable,
};

export default createEditorMetadataEntry(CollapsibleTextEditorMetadata);
