import React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { TranslationKeys } from '../../../constants';

export const dataHooks = {
  emptyStateLink: 'empty-state-action-link',
  horizontalSpacing: 'horizontal-spacing',
  direction: 'direction',
  horizontalPadding: 'horizontal-padding',
  itemsAlignment: 'items-alignment',
  itemsFluid: 'items-fluid',
  menuMode: 'menu-mode',
  textAlignment: 'text-alignment',
  verticalPadding: 'vertical-padding',
  verticalSpacing: 'vertical-spacing',
  horizontalTabs: 'horizontal-tabs',
  columnsAlignment: 'columns-alignment',
  columnsStretch: 'columns-stretch',
  columnsWidth: 'columns-width',
  columnsAmount: 'columns-amount',
  flyoutAlignment: 'flyout-alignment',
  horizontalMargin: 'horizontal-margin',
  rowSpacing: 'row-spacing',
  submenuStretch: 'submenu-stretch',
  submenuMode: 'submenu-mode',
  submenuSpacing: 'submenu-spacing',
  submenuContainerStretch: 'submenu-container-stretch',
};

export const PREVIEW_STATE_MENU_TAB = 'menuTabOpened';
export const PREVIEW_STATE_SUBMENU_TAB = 'submenuTabOpened';
export const PREVIEW_STATE_SUBMENUCONTAINER_TAB = 'submenuContainerTabOpened';

export const MenuMode = {
  Wrap: 'wrap',
  Scroll: 'scroll',
};

export const SubmenuMode = {
  ColumnMenu: 'columnMenu',
  ColumnStretched: 'columnStretched',
  Flyout: 'flyout',
};

export const Selectors = {
  nav: '.root',
  menu: '.root::menu',
  menuItem: '.root::menuItem',
  menuItemLabel: '.root::menuItem::label',
  menuItemContainer: '.root::menuItem::container',
  positionBox: '.root::positionBox',
  scrollButton: '.root::scrollButton',
  scrollButtonIcon: '.root::scrollButton::icon',
  submenu: '.root::columnsLayout',
  submenuFlex: '.root::columnsLayout::flex',
  submenuItem: '.root::columnsLayout::menuItem',
  submenuItemLabel: '.root::columnsLayout::menuItem::label',
  submenuItemContainer: '.root::columnsLayout::menuItem::container',
  submenuListItem: '.root::columnsLayout::listItem',
  submenuRowItem: '.root::columnsLayout::rowItem',
  submenuHeading: '.root::columnsLayout::heading',
  submenuHeadingLabel: '.root::columnsLayout::heading::label',
  submenuHeadingContainer: '.root::columnsLayout::heading::container',
  submenuCategory: '.root::columnsLayout::category',
  submenuColumnsList: '.root::columnsLayout::columnsList',
  submenuListWrapper: '.root::columnsLayout::listWrapper',
  submenuPageWrapper: '.root::columnsLayout::pageWrapper',
  submenuPageStretchWrapper: '.root::columnsLayout::pageStretchWrapper',
  leftAlignmentScrollItem: '.root::leftAlignmentScrollItem',
  rightAlignmentScrollItem: '.root::rightAlignmentScrollItem',
};

export const ArticleHelpIds = {
  columnsWidth: '2c69d1fb-488e-4646-b482-d5d5d6cf44b7',
  columnsStretch: 'a1146f3a-d8fa-41ea-9389-95d7cd45e607',
  layoutPanel: '106ad5a5-28d1-4b49-ac17-607b3c882e8d',
};

export type Direction = 'ltr' | 'rtl';

export const DirectionsThumbnails = [
  {
    value: 'ltr' as Direction,
    illustration: <Symbol name="ltrThumbnail" />,
    label: TranslationKeys.layoutPanel.displayLtr,
  },
  {
    value: 'rtl' as Direction,
    illustration: <Symbol name="rtlThumbnail" />,
    label: TranslationKeys.layoutPanel.displayRtl,
  },
];

export const FLYOUT_ALIGNMENT_THUMBNAILS = [
  {
    value: 'left',
    illustration: <Symbol name="alignLeft" />,
  },
  {
    value: 'center',
    illustration: <Symbol name="alignCenter" />,
  },
];

export const FLYOUT_ALIGNMENT_THUMBNAILS_RTL = [
  {
    value: 'center',
    illustration: <Symbol name="alignCenter" />,
  },
  {
    value: 'right',
    illustration: <Symbol name="alignRight" />,
  },
];

export type Alignment = 'flex-start' | 'center' | 'flex-end';

export const ALIGNMENT_THUMBNAILS = [
  {
    value: 'flex-start' as Alignment,
    illustration: <Symbol name="alignLeft" />,
  },
  {
    value: 'center' as Alignment,
    illustration: <Symbol name="alignCenter" />,
  },
  {
    value: 'flex-end' as Alignment,
    illustration: <Symbol name="alignRight" />,
  },
];

export const ALIGNMENT_THUMBNAILS_RTL = [
  {
    value: 'flex-end' as Alignment,
    illustration: <Symbol name="alignLeft" />,
  },
  {
    value: 'center' as Alignment,
    illustration: <Symbol name="alignCenter" />,
  },
  {
    value: 'flex-start' as Alignment,
    illustration: <Symbol name="alignRight" />,
  },
];

export const MenuModeThumbnails = [
  {
    value: MenuMode.Wrap,
    label: TranslationKeys.layoutPanel.menuModeOptionWrap,
    illustration: <Symbol name="menuWrapMode" />,
  },
  {
    value: MenuMode.Scroll,
    label: TranslationKeys.layoutPanel.menuModeOptionScroll,
    illustration: <Symbol name="menuScrollMode" />,
  },
];

export const SubmenuModeThumbnails = [
  {
    value: SubmenuMode.Flyout,
    label: TranslationKeys.layoutPanel.submenuModeOptionFlyout,
    illustration: <Symbol name="submenuFlyoutMode" />,
  },
  {
    value: SubmenuMode.ColumnMenu,
    label: TranslationKeys.layoutPanel.submenuModeOptionColumn,
    illustration: <Symbol name="submenuColumnsMode" />,
  },
];

export const MENU_MANAGER_PANEL_NAME = {
  EDITOR: 'menu.panels.MenuManagePanel',
  EDITOR_X: 'panel/menu',
};

export const Tab = {
  Menu: 'menu',
  Submenu: 'submenu',
  SubmenuContainer: 'submenu-container',
};

export type Unit = 'px' | '%';

export const unitOptions: Array<{
  label: string;
  value: Unit;
  children: string;
}> = [
  {
    value: '%',
    label: '%',
    children: 'Percentage (%)',
  },
  {
    value: 'px',
    label: 'px',
    children: 'Pixels (px)',
  },
];
