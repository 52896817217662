import { IComponentGFPPDefinition } from '@wix/editor-elements-types';
import { createGFPPEntry } from '@wix/editor-elements-integrations';

export const MegaMenuContainerItemGFPP: IComponentGFPPDefinition = {
  getGfppData() {
    return {
      enabledActions: [],
      mobileEnabledActions: [],
      helpId: 'replace with real id',
      mobileHelpId: 'replace with real id',
    };
  },
};

export default createGFPPEntry(MegaMenuContainerItemGFPP);
