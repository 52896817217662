export const ViewerType = 'wixui.StylableHorizontalMenu' as const;
export const PropertiesType = 'StylableHorizontalMenuProperties';

export const automationId = 'gfpp-button-manage-menu';
export const TranslationKeys = {
  label: 'component_label_stylable_horizontal_menu',
  layoutPanel: {
    header: 'StylablePanel_Layout_Horizontal_Menu_Header',
    menuTab: 'StylablePanel_Design_Horizontal_Menu_Layout_Tab_Menu',
    submenuTab: 'StylablePanel_Design_Horizontal_Menu_Layout_Tab_Submenu',
    submenuContainerTab:
      'StylablePanel_Design_Horizontal_Menu_Layout_Tab_SubmenuContainer',
    menuModeTitle:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Display_Items_Label',
    menuModeTooltip:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Display_Items_Tooltip',
    menuModeOptionWrap:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Display_Items_Option_Wrap',
    menuModeOptionScroll:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Display_Items_Option_Scroll',
    rowSpacing:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Columns_Spacing_Categories',
    columnsAlignment:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Columns_Alignment_Label',
    columnsAlignmentLeft: 'StylablePanel_Design_Layout_Alignment_Left',
    columnsAlignmentCenter: 'StylablePanel_Design_Layout_Alignment_Center',
    columnsAlignmentRight: 'StylablePanel_Design_Layout_Alignment_Right',
    columnsAlignmentJustify: 'StylablePanel_Design_Layout_Alignment_Justify',
    columnsAmount:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Set_Number_Columns_Label',
    columnsVerticalSpacing:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Items_Spacing_Label',
    horizontalSpacing:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Columns_Spacing_Label',
    columnsSectionTitle:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Columns_Section_Title',
    columnsStretch:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Stretch_Columns_Label',
    columnsStretchTooltip:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Stretch_Columns_Tooltip',
    columnsStretchTooltipLink:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Stretch_Columns_Tooltip_Link',
    columnsStretchPageOption:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Stretch_Columns_Page',
    columnsStretchScreenOption:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Stretch_Columns_Screen',
    columnsWidth:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Total_Width_Label',
    columnsWidthTooltipLink:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Total_Width_Tooltip_Link',
    columnsWidthStretchedTooltip:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Total_Width_Tooltip_Submenu_Stretched',
    columnsWidthUnstretchedTooltip:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Total_Width_Tooltip_Submenu_Unstretched',
    itemsAlignment:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Items_Alignment_Label',
    itemsDirection:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Items_Direction_Label',
    itemsDirectionTooltip:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Items_Direction_Tooltip',
    areItemsFluid:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Items_Stretch_Label',
    itemsHorizontalSpacingWrap:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Items_Spacing_Horizontal_Label',
    itemsHorizontalSpacingScroll:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Items_Spacing_Label',
    itemsVerticalSpacing:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Items_Spacing_Vertical_Label',
    itemsSectionTitle:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Spacing_Alignment_Section_Title',
    displayLtr:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Items_Direction_LtoR',
    displayRtl:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Items_Direction_RtoL',
    flyoutAlignment:
      'StylablePanel_Design_Horizontal_Menu_Layout_Tab_Submenu_Flyout_Alignment',
    headingSpacing:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Columns_Spacing_Levels_1_2',
    gridHorizontalSpacing:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Columns_Rows_Spacing_Label',
    menuTextAlignment:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Text_Alignment_Label',
    menuItemSizeSectionTitle:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Item_Padding_Section_Title',
    menuVerticalPadding:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Item_Padding_Vertical_Label',
    menuHorizontalPadding:
      'StylablePanel_Design_Horizontal_Menu_Layout_MainMenu_Item_Padding_Horizontal_Label',
    stretchToFullWidth:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Columns_Stretch_Label',
    submenuHorizontalMargin:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Columns_Margins_Label',
    submenuHorizontalMarginTooltip:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Columns_Margins_Tooltip',
    submenuEmptyStateTitle:
      'StylablePanel_Design_Horizontal_Menu_Layout_Tab_Submenu_Empty_State_Title',
    submenuEmptyStateText1:
      'StylablePanel_Design_Horizontal_Menu_Layout_Tab_Submenu_Empty_State_Text1',
    submenuEmptyStateManageMenuLink:
      'StylablePanel_Design_Horizontal_Menu_Layout_Tab_Submenu_Empty_State_Manage_Menu_Link',
    submenuEmptyStateManagePagesLink:
      'StylablePanel_Design_Horizontal_Menu_Layout_Tab_Submenu_Empty_State_Pages_Link',
    submenuModeTitle:
      'StylablePanel_Design_Horizontal_Menu_Layout_Tab_Submenu_Type_Label',
    submenuModeOptionFlyout:
      'StylablePanel_Design_Horizontal_Menu_Layout_Tab_Submenu_Type_Option_Flyout',
    submenuModeOptionColumn:
      'StylablePanel_Design_Horizontal_Menu_Layout_Tab_Submenu_Type_Option_Columns',
    submenuModeVerticalSpacing:
      'StylablePanel_Design_Horizontal_Menu_Layout_Tab_Submenu_Flyout_Vertical_Spacing_Label',
    submenuTextAlignment:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Text_Alignment_Label',
    submenuItemsSectionTitle:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Columns_Items_Spacing_Padding_Section_Title',
    submenuVerticalPadding:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Item_Padding_Vertical_Label',
    submenuHorizontalPadding:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Item_Padding_Horizontal_Label',
    submenuOrder:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Columns_Order_Label',
    submenuOrderVertically:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Columns_Order_Vertically',
    submenuOrderHorizontally:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Columns_Order_Horizontally',
    submenuSpacing:
      'StylablePanel_Design_Horizontal_Menu_Layout_Submenu_Spacing_Menu_Submenu_Label',
  },
  gfpp: {
    manageMenu: 'gfpp_mainaction_stylable_horizontal_menu',
    navigate: 'gfpp_secondaryaction_stylable_horizontal_menu',
  },
};

export const ComponentMetaData = {
  displayName: 'StylableHorizontalMenu',
  componentType: ViewerType,
  nickName: 'horizontalMenu',
  skinName: 'wixui.skins.StylableHorizontalMenu',
  corvidName: 'HorizontalMenu',
};
