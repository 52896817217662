import { IEditorMetadata } from '@wix/editor-elements-types';
import {
  createEditorMetadataEntry,
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-elements-integrations';

import { ISliderData, ISliderProperties } from '../Slider.types';

const getVerticallyResizable = editorDataFunction(
  componentInstanceData('componentProperties'),
  (properties: ISliderProperties) => properties.orientation === 'vertical',
);

const geHorizontallyResizable = editorDataFunction(
  componentInstanceData('componentProperties'),
  (properties: ISliderProperties) => properties.orientation === 'horizontal',
);

const getSkinParamsToIgnore = editorDataFunction(
  componentInstanceData('componentProperties'),
  componentInstanceData('componentData'),
  (
    { tickMarksShape, tooltipVisibility, thumbShape }: ISliderProperties,
    { step }: ISliderData,
  ) => {
    const ignoreList = [];

    if (tickMarksShape === 'none' || !step) {
      ignoreList.push('tickMarkColor', 'tickMarkColorHover');
    }

    if (tooltipVisibility === 'none') {
      ignoreList.push(
        'tooltipBackground',
        'tooltipBackgroundHover',
        'tooltipFont',
        'tooltipTextColor',
        'tooltipBorderColor',
        'tooltipBorderColorHover',
        'tooltipBorderWidth',
        'tooltipBorderWidthHover',
        'tooltipBorderRadius',
        'tooltipBoxShadow',
        'tooltipFontSize',
        'tooltipFontFamily',
        'tooltipFontColor',
      );
    }

    if (thumbShape === 'circle') {
      ignoreList.push('thumbBorderRadius');
    }

    return ignoreList;
  },
);

const getGfppMargins = editorDataFunction(
  componentInstanceData('componentProperties'),
  (compProps: ISliderProperties) => {
    if (
      compProps.orientation === 'horizontal' &&
      compProps.tooltipVisibility !== 'none'
    ) {
      return 52;
    }

    return undefined;
  },
);

export const SliderEditorMetadata: IEditorMetadata = {
  verticallyResizable: getVerticallyResizable,
  horizontallyResizable: geHorizontallyResizable,
  skinParamsToIgnore: getSkinParamsToIgnore,
  gfppMargins: getGfppMargins,
  allowConnectToDB: true,
};

export default createEditorMetadataEntry(SliderEditorMetadata);
