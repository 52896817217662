import { IComponentGFPPDefinition } from '@wix/editor-elements-types';
import {
  createGFPPEntry,
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '@wix/editor-elements-integrations';

const SliderGFPP: IComponentGFPPDefinition = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened('settings'),
    (se_gfppSelectedMainAction: boolean, isSettingsPanelOpened: boolean) => ({
      mainActions: [
        {
          label: 'gfpp_mainaction_slider_settings',
          isSelected: se_gfppSelectedMainAction && isSettingsPanelOpened,
          isApplied: true,
          actionType: 'settings',
        },
      ],
      enabledActions: ['layout', 'design', 'animation', 'help'],
      mobileEnabledActions: ['hide', 'help'],
      helpId: 'd0ec1d00-d02b-424f-868e-3c57805bf4e8',
    }),
  ),
};

export default createGFPPEntry(SliderGFPP);
