(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("santa-editor-symbols"), require("santa-editor-utils"));
	else if(typeof define === 'function' && define.amd)
		define("rb_wixui.editor_gfpp-metadata", ["react", "santa-editor-symbols", "santa-editor-utils"], factory);
	else if(typeof exports === 'object')
		exports["rb_wixui.editor_gfpp-metadata"] = factory(require("react"), require("santa-editor-symbols"), require("santa-editor-utils"));
	else
		root["rb_wixui.editor_gfpp-metadata"] = factory(root["React"], root["santa-editor-symbols"], root["santa-editor-utils"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__51__, __WEBPACK_EXTERNAL_MODULE__92__) {
return 