export const ViewerType = 'wixui.MultiStateBox' as const;

export const TranslationKeys = {
  gfppHelpId: 'help.gfpp-MultiStateBox',
  manageItemsGfppAction: 'gfpp_mainaction_multiStateBox',
} as const;

export const Skins = {
  containerDefaultSkin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
} as const;

export const TestIds = {
  AddPanel: 'multi-state-box-add-panel-section',
  ManageItemsAction: 'multi-state-box-manage-items-panel-gfpp-action',
  ManageItemsPanelContainer: 'multi-state-box-manage-items-panel-container',
  SortDragList: 'SortDragList',
  AddStateButton: 'AddStateButton',
  DuplicateStateButton: 'DuplicateStateButton',
  EditStateNameMenuItem: 'EditStateNameMenuItem',
  DuplicateStateMenuItem: 'DuplicateStateMenuItem',
  DeleteStateMenuItem: 'DeleteStateMenuItem',
} as const;
