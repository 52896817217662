import { IComponentGFPPDefinition } from '@wix/editor-elements-types';
import {
  createGFPPEntry,
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '@wix/editor-elements-integrations';

export const BreadcrumbsGFPP: IComponentGFPPDefinition = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened('settings'),
    (se_gfppSelectedMainAction: boolean, isSettingsPanelOpened: boolean) => ({
      mainActions: [
        {
          label: 'gfpp_mainaction_breadcrumbs',
          isSelected: se_gfppSelectedMainAction && isSettingsPanelOpened,
          actionType: 'settings',
        },
      ],
      enabledActions: ['layout', 'design', 'animation', 'stretch', 'help'],
      mobileEnabledActions: [
        'settings',
        'layout',
        'design',
        'animation',
        'hide',
        'help',
      ],
      helpId: 'c8b1d3da-3897-462d-ae99-a570747e2e67',
      mobileHelpId: 'c8b1d3da-3897-462d-ae99-a570747e2e67',
    }),
  ),
};

export default createGFPPEntry(BreadcrumbsGFPP);
