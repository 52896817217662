import { IEditorMetadata } from '@wix/editor-elements-types';
import { createEditorMetadataEntry } from '@wix/editor-elements-integrations';

const MultiStateBoxEditorMetadata: IEditorMetadata = {
  allowConnectToDB: true,
  canContain: false,
  canContainByStructure: false,
  focusable: true,
};

export default createEditorMetadataEntry(MultiStateBoxEditorMetadata);
