import { IComponentGFPPDefinition } from '@wix/editor-elements-types';
import {
  createGFPPEntry,
  editorDataFunction,
  experiment,
  isComponentPanelOpened,
} from '@wix/editor-elements-integrations';
import { TestIds, TranslationKeys } from '../constants';

export const MultiStateBoxGFPP: IComponentGFPPDefinition = {
  getGfppData: editorDataFunction(
    experiment('se_gfppSelectedMainAction'),
    isComponentPanelOpened('manage'),
    (se_gfppSelectedMainAction: boolean, isManagePanelOpened: boolean) => ({
      mainActions: [
        {
          actionType: 'manage',
          isSelected: se_gfppSelectedMainAction && isManagePanelOpened,
          label: TranslationKeys.manageItemsGfppAction,
          automationId: TestIds.ManageItemsAction,
        },
      ],
      enabledActions: ['manage', 'design', 'animation', 'help'],
      mobileEnabledActions: [],
      helpId: '340e3cee-05f5-4b1d-958a-700bef679365',
      mobileHelpId: '',
    }),
  ),
};

export default createGFPPEntry(MultiStateBoxGFPP);
