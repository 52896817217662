import { IComponentGFPPDefinition } from '@wix/editor-elements-types';
import { createGFPPEntry } from '@wix/editor-elements-integrations';

export const TestCompGFPP: IComponentGFPPDefinition = {
  getGfppData() {
    return {
      enabledActions: ['design'],
      mobileEnabledActions: [],
      helpId: 'replace with real id',
      mobileHelpId: 'replace with real id',
    };
  },
};

export default createGFPPEntry(TestCompGFPP);
