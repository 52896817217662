import { isOnEditorX } from '@wix/santa-editor-utils';
import { MENU_MANAGER_PANEL_NAME } from './panels/layout/constants';

export const getAlignmentImage = ({
  baseUrl,
  layoutType,
  isHover = false,
  isSelected = false,
}: {
  baseUrl: string;
  layoutType: string;
  isHover?: boolean;
  isSelected?: boolean;
}) => {
  const folderPrefix = isHover ? 'gifs' : 'pngs';
  const selectedSuffix = isSelected ? '-selected' : '';
  const extension = isHover ? 'gif' : 'png';

  return `${baseUrl}/assets/HorizontalMenu/${folderPrefix}/${layoutType}-align${selectedSuffix}.${extension}`;
};

export const alignmentTypes = ['left', 'center', 'right', 'justify'];

export function getAlignmentThumbnailsCSS({
  classes,
  baseUrl,
}: {
  classes: Record<string, string>;
  baseUrl: string;
}) {
  const baseStyles = `
  .${classes.hiddenImagePreloader} {
    display: none;
  }

  .${classes.alignThumbnail} {
    width: 51px;
    height: 51px;
    background: no-repeat 50% 50%;
  }
  `;

  const additionalStyles = alignmentTypes.map(type => {
    return `
  .${classes.alignThumbnail}.${classes[type]} {
    background-image: url('${getAlignmentImage({
      baseUrl,
      layoutType: type,
    })}');
  }

  .${classes.alignThumbnail}.${classes[type]}:hover {
    background-image: url('${getAlignmentImage({
      baseUrl,
      layoutType: type,
      isHover: true,
    })}');
  }

  .selected .${classes.alignThumbnail}.${classes[type]} {
    background-image: url('${getAlignmentImage({
      baseUrl,
      layoutType: type,
      isSelected: true,
    })}');
  }

  .selected .${classes.alignThumbnail}.${classes[type]}:hover {
    background-image: url('${getAlignmentImage({
      baseUrl,
      layoutType: type,
      isSelected: true,
      isHover: true,
    })}');
  }`;
  });

  return baseStyles + additionalStyles.join('');
}

export const getNumberValue = (value = '') => {
  const number = parseFloat(value);
  return isFinite(number) ? number : 0;
};

export const getUnitValue = (value = '') =>
  value.replace(getNumberValue(value).toString(), '');

export const openPagesPanel = (
  editorAPI: Record<string, any>,
  menuId: string,
) => {
  editorAPI.openPagesPanel({ menuId: menuId.replace('#', '') });
};

export const openMenuManager = (editorAPI: Record<string, any>) => {
  const panelName = isOnEditorX()
    ? MENU_MANAGER_PANEL_NAME.EDITOR_X
    : MENU_MANAGER_PANEL_NAME.EDITOR;
  const openedPanels = editorAPI.panelManager.getOpenPanels();

  const isMenuManagerOpened = openedPanels.find(
    (panel: { name: string }) => panel.name === panelName,
  );

  if (isMenuManagerOpened) {
    editorAPI.panelManager.closePanelByName(panelName);
  } else {
    editorAPI.panelManager.closeAllPanels();
    editorAPI.panelManager.openComponentPanel(panelName, {
      useLastPanelPosition: true,
    });
  }
};
