import { IEditorMetadata } from '@wix/editor-elements-types';
import { createEditorMetadataEntry } from '@wix/editor-elements-integrations';

const UnsupportedComponentEditorMetadata: IEditorMetadata = {
  allowConnectToDB: false,
  pinnable: false,
  duplicatable: false,
  containable: false,
  canContain: false,
  containableByStructure: false,
  canContainByStructure: false,
  movable: false,
  verticallyMovable: false,
  horizontallyMovable: false,
  resizable: false,
  verticallyResizable: false,
  horizontallyResizable: false,
  multiselectable: false,
  resizableWithAnchors: false,
  resizableDiagonally: false,
  allowedToContainMoreChildren: false,
  isDirectChildrenSelectable: false,
  arrangeable: false,
  rotatable: false,
};

export default createEditorMetadataEntry(UnsupportedComponentEditorMetadata);
